.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AppBar .Toolbar .MuiTypography-h6 {
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Georgia', serif;
}

.MuiPaper-root {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.MuiPaper-root:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-size: cover;
  background-position: center;
}

/* Media query para dispositivos móveis */
@media (max-width: 768px) {
  .title-bar h1 {
    margin-left: 0; /* Remove a margem em dispositivos móveis */
    font-size: 2rem; /* Opcional: Ajuste o tamanho da fonte para telas menores */
  }
}

.title-bar h1 {
  flex: 1;
  color: #ffffff;
  font-weight: 900;
  font-size: 3rem;

}

.menu-items {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.menu-items button {
  color: #ffffff;
}

